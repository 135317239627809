import { Component, OnInit, LOCALE_ID, Inject } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { AuthService } from '../../../core/services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { UntypedFormControl } from '@angular/forms';
import { SearchService } from '../../../core/services/search.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ApiService } from '../../../core/services/api.service';
import { NotificationService } from '../../../core/services/notification.service';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { NavigationModel } from 'src/app/core/models/navigation.model';
import { NavigationItemModel } from 'src/app/core/models/navigation-item.model';
import { ChangePasswordDialogComponent } from 'src/app/modules/components/change-password-dialog/change-password-dialog.component';

import { TranslateService } from '@ngx-translate/core';
import { Role } from 'src/app/core/models/enums/roles';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('search', [
      state('void', style({ opacity: 0, transform: 'translateY(-10px)' })),
      transition('void <=> *', animate('.35s ease'))
    ])
  ]
})
export class AppComponent implements OnInit {
  public handsetBreakpoint = false;
  public searchEnabled: boolean = false;
  public trackingTotal = 0;
  public profileName = '';
  public searchControl = new UntypedFormControl('');
  public searchText = '';

  currentUserRoleID = localStorage.getItem('user_role_id');
  allItems: NavigationItemModel[] = [];
  public navItems: Array<NavigationModel> = []


  // Array object that we use.
  displayMenuItems: NavigationItemModel[] = this.traverseMenuItems(this.navItems);
  arrayMenuItems: Array<any> = [];
  isEnableOrderDoc: boolean;
  constructor(private breakpointObserver: BreakpointObserver,
    private auth: AuthService,
    private dialog: MatDialog,
    private api: ApiService,
    private search: SearchService,
    public notification: NotificationService,
    @Inject(LOCALE_ID) protected localeId: string,
    public translate: TranslateService,
    private router: Router) {
    this.api.getOrderDocConfig().subscribe(res => {
      this.isEnableOrderDoc = !res.config?.disable_order_doc;
      this.getNavItems();
    })
    this.breakpointObserver.observe(['(max-width: 1000px)']).subscribe(res => {
      this.handsetBreakpoint = res.matches;
    });
    if (localStorage.getItem('locale') != null) {
      translate.setDefaultLang(localStorage.getItem('locale'));
    } else {
      translate.setDefaultLang('kh');
    }
    if (this.handsetBreakpoint) {
      // start

    }
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.getNavItems();
      }
    });
  }

  getNavItems() {
    this.api.countByMenu().subscribe(res => {
      this.translate.get('left_menu').subscribe((left_menu: any) => {
        this.navItems = [
          {
            groupTitle: left_menu.dashboard.title,
            items: [
              {
                title: left_menu.dashboard.menu_dashboard,
                link: '/summary',
                icon: 'dashboard',
                roles: [Role.ADMIN, Role.USER, Role.LEADER, Role.SUPER_LEADER, Role.GD_LEADER],
              }
            ]
          },
          {
            groupTitle: left_menu.in_charge_documents.title,
            items: [
              {
                title: left_menu.in_charge_documents.pending_document,
                icon: 'create_new_folder',
                link: '/documents/pending',
                roles: [Role.ADMIN, Role.USER, Role.LEADER, Role.SUPER_LEADER, Role.GD_LEADER],
                count: res.count?.doc_request_dpm
              },
              {
                title: left_menu.in_charge_documents.approved_document,
                icon: 'rule_folder',
                link: '/documents/approved',
                roles: [Role.ADMIN, Role.USER, Role.LEADER, Role.GD_LEADER],
                count: res.count?.doc_approved_dpm
              },
            ]
          },
          {
            groupTitle: left_menu.tracking.title,
            items: [
              {
                title: left_menu.tracking.search,
                icon: 'pageview',
                link: '/documents/search',
                roles: [Role.ADMIN, Role.USER, Role.LEADER, Role.SUPER_LEADER, Role.GD_LEADER]
              },
              {
                title: left_menu.tracking.sent_back,
                icon: 'mark_email_unread',
                link: '/documents/tracking-send-back',
                roles: [Role.ADMIN, Role.USER, Role.LEADER, Role.GD_LEADER],
                count: res.count?.doc_send_back_edit
              },
              {
                title: left_menu.tracking.sent_to,
                icon: 'forward_to_inbox',
                link: '/documents/tracking-request',
                roles: [Role.ADMIN, Role.USER, Role.LEADER, Role.GD_LEADER],
                count: res.count?.doc_send_request_approve
              },
              {
                title: left_menu.tracking.recently_deleted,
                icon: 'delete',
                link: '/documents/deleted',
                roles: [Role.ADMIN, Role.USER, Role.LEADER, Role.GD_LEADER]
              }
            ]
          },
          {
            groupTitle: left_menu.reports.title,
            items: [
              {
                title: left_menu.reports.menu_report,
                link: '/reports',
                icon: 'insert_drive_file',
                roles: [Role.ADMIN]
              }
            ]
          },
          {
            groupTitle: left_menu.settings.title,
            items: [
              {
                title: left_menu.settings.menu_office,
                link: '/offices',
                icon: 'store_mall_directory',
                roles: [Role.ADMIN]
              },
              {
                title: left_menu.settings.menu_docType,
                icon: 'folder_open',
                link: '/documents/types',
                roles: [Role.ADMIN]
              },
              {
                title: left_menu.settings.menu_document_source,
                icon: 'store_mall_directory',
                link: '/documents/source',
                roles: [Role.ADMIN]
              },
              {
                title: left_menu.settings.menu_docFlow,
                link: '/doc-flow',
                icon: 'directions',
                roles: [Role.ADMIN]
              },
              {
                title: left_menu.settings.menu_userManagement,
                link: '/users/management',
                icon: 'group',
                roles: [Role.ADMIN]
              }
            ]
          }
        ];
        this.displayMenuItems = this.traverseMenuItems(this.navItems);
      });
    })
  }

  switchLanguage(language: string) {
    this.translate.use(language).subscribe(() => {
      this.getNavItems();
    });
    this.auth.saveLocale(language);
  }

  public async signOut() {
    await this.auth.signOut();
  }

  ngOnInit(): void {
    this.getUserProfile();
    this.notification.refreshNotification();
    this.searchControl
      .valueChanges
      .pipe(filter(x => x),
        debounceTime(500),
        distinctUntilChanged())
      .subscribe(ax => {
      });

    this.search.visibilityObservable().subscribe(x => {
      this.searchControl.reset();
      this.searchEnabled = x;
    });

    this.search.initText.subscribe(text => {
      if (text != this.searchControl.value) {
        this.searchControl.setValue(text, { emitEvent: false })
      }
    })

    this.api.countTracking().subscribe(response => response.status && (this.trackingTotal = response.data));
  }

  showChange(searchText: string) {
    this.search.emitText(searchText);
  }

  getUserProfile() {
    this.api.getProfile().subscribe(response => {
      this.auth.saveUserRole(response.profile.role._id)
      this.auth.saveGroupID(response.profile.group?._id)
      this.auth.saveGroupPriority(response.profile.group?.is_priority)
      this.profileName = response.profile.title + " " + response.profile.last_name + " " + response.profile.first_name;
    });
  }


  /**
   * Filter menu to be displayed
   * @param navItems
   */
  private traverseMenuItems(navItems: NavigationModel[]) {
    let hasItem = false;
    this.arrayMenuItems = [];
    let isGroupPriority = this.auth.isUserGroupPriority();
    navItems.forEach((nav, index) => {
      let arrayMenuItem = { groupTitle: "", items: [] };
      arrayMenuItem.groupTitle = nav.groupTitle;
      nav.items.forEach(item => {
        if ((!item.roles && !item.priority_group) || (item.roles && item.roles.includes(this.currentUserRoleID)) || (item.priority_group && isGroupPriority)) {
          arrayMenuItem.items.push(item);
          hasItem = true;
        }
      });

      if (hasItem) {
        this.arrayMenuItems.push(arrayMenuItem);
      }
      hasItem = false;
    });

    return this.arrayMenuItems;
  }

  openDialogChangePassword() {
    this.dialog.open<ChangePasswordDialogComponent>(ChangePasswordDialogComponent, {
      panelClass: 'less-shadow',
      width: '400px',
      data: {},
    }).afterClosed().subscribe(response => {
      if (response) {
        console.log("TO DO ...");
      }
    });
  }
}

export interface MenuItem {
  title: string; // Title to be displayed
  exact?: boolean; // Highlight when active or not
  routerLink?: string; // Link to redirect when click
  children?: MenuItem[]; // Children of the menu
  roles?: string[]; // Allowed roles for the menu
  icon?: string; // Icon to be displayed
  expanded?: boolean; // Show children or not
}
