import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserModel } from '../../../core/models/user.model';
import { UntypedFormControl, Validators, UntypedFormGroup } from '@angular/forms';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-change-password-dialog',
  templateUrl: './change-password-dialog.component.html',
  styleUrls: ['./change-password-dialog.component.scss']
})
export class ChangePasswordDialogComponent implements OnInit {

  userID: string = "";
  hide_new = true;
  hide_old = true;
  hide_confirm = true;
  checkBothPassword: boolean = false;
  checkConfirmPassword: boolean = false;
  checkWrongPassword: boolean = false;

  // ===== Form Validation ======================================================
  oldPasswordFormControl: UntypedFormControl = new UntypedFormControl('', [
    Validators.required,
    Validators.minLength(6)
  ]);

  newPasswordFormControl: UntypedFormControl = new UntypedFormControl('', [
    Validators.required,
    Validators.minLength(6)
  ]);

  confirmPasswordFormControl: UntypedFormControl = new UntypedFormControl('', [
    Validators.required,
    Validators.minLength(6)
  ]);

  changePasswordFormGroup: UntypedFormGroup = new UntypedFormGroup({
    old_password: this.oldPasswordFormControl,
    new_password: this.newPasswordFormControl,
    confirm_password: this.confirmPasswordFormControl
  });

  getOldPasswordErrorMessage() {
    return this.oldPasswordFormControl.hasError('required') ? 'You must enter a value' :
      this.oldPasswordFormControl.hasError('minlength') ? 'Password must be at least 6 characters long' : '';
  }

  getNewPasswordErrorMessage() {
    return this.newPasswordFormControl.hasError('required') ? 'You must enter a value' :
      this.newPasswordFormControl.hasError('minlength') ? 'Password must be at least 6 characters long' : '';
  }

  getConfirmPasswordErrorMessage() {
    return this.confirmPasswordFormControl.hasError('required') ? 'You must enter a value' :
      this.confirmPasswordFormControl.hasError('minlength') ? 'Password must be at least 6 characters long' : '';
  }
  // ===========================================================================

  constructor(@Inject(MAT_DIALOG_DATA) public data: UserModel,
    private snackBar: MatSnackBar,
    private auth: AuthService,
    private dialogRef: MatDialogRef<ChangePasswordDialogComponent>) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
  }

  handleChangePassword() {
    this.checkWrongPassword = false;
    if (this.oldPasswordFormControl.value === this.newPasswordFormControl.value) {
      this.checkBothPassword = true;
    } else if (this.confirmPasswordFormControl.value !== this.newPasswordFormControl.value) {
      this.checkConfirmPassword = true;
      this.confirmPasswordFormControl.setValue('');
    } else {
      this.auth.changePassword(this.newPasswordFormControl.value, this.oldPasswordFormControl.value).subscribe((response: any) => {
        if (response.status !== 1) {
          this.checkWrongPassword = true;
          this.checkConfirmPassword = false;
          this.oldPasswordFormControl.setValue('');
        } else {
          this.snackBar.open('changed successfully', null, { duration: 2000 });
          this.dialogRef.close();
        }
      });
    }
  }
}
