import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivationStart, Router, NavigationStart, NavigationEnd, NavigationCancel } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { Location } from "@angular/common";

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
  isLoading = false
  constructor(private titleService: Title,
    private router: Router,
    private location: Location) {
  }

  ngOnInit() {
    this.router.events
      .pipe(filter(x => x instanceof ActivationStart),
        filter(x => x['snapshot']),
        filter(x => x['snapshot']['data']),
        filter(x => x['snapshot']['data']['title']),
        map(x => x['snapshot']['data']['title'])
      ).subscribe(event => {
        this.titleService.setTitle(event);
      });
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.isLoading = true;
      }
      else if (
        event instanceof NavigationEnd ||
        event instanceof NavigationCancel
      ) {
        this.isLoading = false;
      }
    })
  }

}
