import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { LoginResponse } from '../models/responses/login.response';
import { DocumentModel } from '../models/document.model';
import { DocumentsResponse } from '../models/responses/documents.response';
import { DocumentStatusResponse } from '../models/responses/document-status.response';
import { CreateDocumentTypeResponse, DocumentTypeResponse } from '../models/responses/document-type.response';
import { CreateDocumentResponse } from '../models/responses/create-document.response';
import { OfficeResponse } from '../models/responses/office.response';
import { OfficeModel } from '../models/office.model';
import { CreateOfficeResponse } from '../models/responses/create-office.response';
import { CommonResponse } from '../models/responses/common.response';
import { RoleResponse } from '../models/responses/role.response';
import { TrackingStatusResponse } from '../models/responses/tracking-status.response';
import { UsersResponse } from '../models/responses/users.response';
import { UserModel } from '../models/user.model';
import { ProfileResponse } from '../models/responses/profile.response';
import { DocumentTypeModel } from '../models/document-type.model';
import { OrganizationResponse, OrganizationsResponse } from '../models/responses/organization.response';
import { TrackingsModelResponse } from '../models/tracking.model';
import { ReportsResponse } from '../models/responses/reports.response';
import { DocFlowsResponse } from '../models/responses/doc-flows.response';
import { FlowModel } from '../models/flow.model';
import { DocFlowResponse } from '../models/responses/doc-flow.response';
import { UserDocType } from '../models/enums/user-doc-types';
import { DocumentTrackingResponse } from '../models/responses/document-tracking.response';
import { DocumentBadgeResponse } from '../models/responses/document-badge.response';
import { CheckIsSender } from '../models/responses/check-is-sender.response';
import { CountByMenuResponse } from '../models/responses/count-by-menu.response';
import { GeneralDepartmentResponse, GeneralDepartmentsResponse } from '../models/responses/general-department.response';
import { DepartmentResponse, DepartmentsResponse } from '../models/responses/general-department.response copy';
import { GroupsResponse } from '../models/responses/user-groups.response';
import { UserGroupModel } from '../models/user-group.model';
import { CreateUserGroupResponse } from '../models/responses/create-user-group.response';
import { DashboardCountResponse } from '../models/responses/dashboard-count.response';
import { UserGroupConfigResponse } from '../models/responses/user-group-config.response';
import { OrderDocConfigResponse } from '../models/responses/order-doc-config.response';
import { DocumentResponse } from '../models/responses/document.response';
import { DocumentStatus } from '../models/enums/doc-status';


export enum Endpoints {
  login = 'login',
  profile = 'profile',
  docs = 'docs',
  docsSearch = 'docs/search',
  docsDeleted = 'docs/deleted',
  trackingDocs = 'docs/tracks',
  docById = 'docs/:id',
  shareDocById = 'docs/share/:doc_id',
  parentDoc = 'docs/replied?id=:id',
  parentDocWithoutID = 'docs/replied',
  editDocById = 'docs/:id',
  editDocPriorityById = 'docs/priority/:id',
  restoreDocById = 'docs/restore/:id',
  searchDoc = 'docs?q=:q',
  searchParentDoc = 'docs/replied?q=:q',
  docStatus = 'doc_status',
  docTypes = 'doc_types',
  docTypesById = 'doc_types/:id',
  offices = 'offices',
  officeTree = 'offices/tree',
  groups = 'user_groups',
  nextLevelDocFlow = 'doc_flows/next',
  docFlows = 'doc_flows',
  docFlowsById = 'doc_flows/:id',
  docFlowConnectionById = 'doc_flows/connection/:id',
  officeTypes = 'office_types',
  officeTypesById = 'office_types/:id',
  officeById = 'offices/:id',
  groupById = 'user_groups/:id',
  officeByName = 'offices/name/:name',
  roles = 'roles',
  trackingStatus = 'tracking_status',
  uploadFileToDocumentById = 'docs/upload/:id',
  downloadFileById = 'docs/download/:doc_id/:file_id?token=:token',
  deleteFileByID = 'docs/file/:doc_id/:id',
  users = 'users',
  checkIsSender = 'users/is_sender',
  reports = 'report',
  resetPassword = 'users/reset/',
  usersById = 'users/:id',
  usersByName = 'users/name/:name',
  activateUserById = 'users/activate/:id',
  deactivateUserById = 'users/deactivate/:id',
  usersOfficeById = 'users/office/:id',
  usersForShareByDocId = 'users/share/:doc_id',
  organization = 'organization',
  general_department = 'general_department',
  department = 'department',
  organizationById = 'organization/:id',
  trackingSendByDocumentId = 'tracking/send/:id',
  trackingSendBackByDocumentId = 'tracking/send_back/:id',
  trackingOffice = 'tracking/office',
  trackingDownloadFileById = 'tracking/download/:doc_id/:tracking_id/:file_id?token=:token',
  trackingAudioDownloadFileById = 'tracking/download/voice/:doc_id/:tracking_id/:file_id?token=:token',
  trackingCount = 'tracking/count-total',
  trackingReceivedByDocId = 'tracking/received/:id',
  trackingCompleteByDocId = 'tracking/complete/:id',
  trackingDocsById = 'tracking/docs/:id',
  changePassword = 'change_pwd',
  getDashboardCount = 'report/count?start_date=:start_date&end_date=:end_date',
  getDashboardAdminCount = 'report/count/admin?start_date=:start_date&end_date=:end_date',
  barChartInfo = 'report/summary/month?date=:date',
  markAsCompleted = 'tracking/mark-as-completed/:doc_id',
  unsend = 'tracking/cancel_sending/:doc_id',
  getBack = 'tracking/get_back/:doc_id',
  cancelApproved = 'tracking/cancel_approved/:doc_id',
  docBadge = 'docs/count/priority',
  courntByMenu = 'docs/count-by-menu',
  configById = 'config/:id',
}

@Injectable({ providedIn: 'root' })
export class ApiService {

  constructor(private http: HttpClient) {

  }

  public static getUrl(end: Endpoints): string {
    return environment.baseUrl.concat(end);
  }

  public static getUrlWithParams(end: Endpoints | string, params: { [key: string]: string }): string {
    const regex = new RegExp(Object.keys(params).map(key => `:${key}`).join('|'), 'gi');
    return environment.baseUrl.concat(end).replace(regex, matched => {
      if (!matched) {
        return '';
      }
      return encodeURI(params[matched.replace(':', '')]);
    });
  }

  public login({ userName, password }: { userName: string, password: string }): Observable<LoginResponse> {
    return this.http.post<LoginResponse>(ApiService.getUrl(Endpoints.login), { name: userName, pwd: password });
  }

  public getSaveOnlyDocumentByPage(currentPage = 1, limit = 9999, q = '') {
    return this.http.get<DocumentsResponse>(ApiService.getUrl(Endpoints.docs), {
      params: {
        page: currentPage.toString(10),
        limit: limit.toString(10),
        is_approved: 'false',
        q,
        is_save_only: 'true'
      }
    });
  }
  public getDocumentsByPage(currentPage = 1, limit = 9999, type: UserDocType, status: DocumentStatus, isTracking: boolean = false, priority: number, searchText = ''): Observable<DocumentsResponse> {
    return this.http.get<DocumentsResponse>(ApiService.getUrl(Endpoints.docs), {
      params: {
        page: currentPage.toString(10),
        limit: limit.toString(10),
        q: searchText,
        type: type ? type : '',
        status: status ?? '',
        is_tracking: isTracking,
        priority: priority == 0 ? '' : priority.toString()
      }
    });
  }

  public searchDocumentsByPage(currentPage = 1, limit = 9999, data: any): Observable<DocumentsResponse> {
    for (const propName in data) {
      if (data[propName] === null || data[propName] === undefined || data[propName] === '') {
        delete data[propName];
      }
    }
    return this.http.get<DocumentsResponse>(ApiService.getUrl(Endpoints.docsSearch), {
      params: {
        page: currentPage.toString(10),
        limit: limit.toString(10),
        ...data
      }
    });
  }

  public searchDocumentsDeteledByPage(currentPage = 1, limit = 9999, data: any): Observable<DocumentsResponse> {
    for (const propName in data) {
      if (data[propName] === null || data[propName] === undefined || data[propName] === '') {
        delete data[propName];
      }
    }
    return this.http.get<DocumentsResponse>(ApiService.getUrl(Endpoints.docsDeleted), {
      params: {
        page: currentPage.toString(10),
        limit: limit.toString(10),
        ...data
      }
    });
  }


  public getDocumentBadge(is_approved: boolean, type: UserDocType, is_order: boolean, is_save_only: boolean = false): Observable<DocumentBadgeResponse> {
    return this.http.get<DocumentBadgeResponse>(ApiService.getUrl(Endpoints.docBadge), {
      params: {
        is_approved: is_approved ? 'true' : 'false',
        is_save_only: is_save_only ? 'true' : 'false',
        type: type ? type : '',
        is_order: is_order != null ? is_order.toString() : ''
      }
    });
  }

  public getTrackingDocumentsByPage(currentPage = 1, limit = 9999, is_approved: boolean, is_send_back: boolean, group_by = "day", searchText = ''): Observable<DocumentTrackingResponse> {
    return this.http.get<DocumentTrackingResponse>(ApiService.getUrl(Endpoints.trackingDocs) + '/' + group_by, {
      params: {
        page: currentPage.toString(10),
        limit: limit.toString(10),
        q: searchText,
        approved: is_approved != null ? is_approved.toString() : '',
        send_back: is_send_back != null ? is_send_back.toString() : '',
      }
    });
  }

  public getCountDocuments(type: UserDocType = UserDocType.CREATOR): Observable<{ status: number, total: number }> {
    return this.http.get<{ status: number, total: number }>(ApiService.getUrl(Endpoints.docs), {
      params: {
        type
      }
    });
  }

  public changeOwnPassword(newPassword: string, oldPassword: string) {
    return this.http.post<any>(ApiService.getUrl(Endpoints.changePassword), {
      old_pwd: oldPassword,
      new_pwd: newPassword
    });
  }

  public createDocument(document: DocumentModel): Observable<CreateDocumentResponse> {
    return this.http.post<CreateDocumentResponse>(ApiService.getUrl(Endpoints.docs), document);
  }

  public editDocument(docID: string, document: DocumentModel): Observable<CreateDocumentResponse> {
    return this.http.patch<CreateDocumentResponse>(ApiService.getUrlWithParams(Endpoints.editDocById, { id: docID }), document);
  }
  public editDocumentPriority(docID: string, priority: number): Observable<CreateDocumentResponse> {
    return this.http.patch<CreateDocumentResponse>(ApiService.getUrlWithParams(Endpoints.editDocPriorityById, { id: docID }), { priority });
  }
  public getDocumentStatuses(): Observable<DocumentStatusResponse> {
    return this.http.get<DocumentStatusResponse>(ApiService.getUrl(Endpoints.docStatus));
  }

  public getDocumentById(id: string): Observable<DocumentResponse> {
    return this.http.get<DocumentResponse>(ApiService.getUrlWithParams(Endpoints.docById, { id: id }));
  }

  public getParentDocument(id: string): Observable<CreateDocumentResponse> {
    return this.http.get<CreateDocumentResponse>(ApiService.getUrlWithParams(Endpoints.parentDoc, { id }));
  }

  public getParentDocumentWithoutID(): Observable<CreateDocumentResponse> {
    return this.http.get<CreateDocumentResponse>(ApiService.getUrl(Endpoints.parentDocWithoutID));
  }

  public searchParentDocument(searchText: string) {
    return this.http.get<CreateDocumentResponse>(ApiService.getUrlWithParams(Endpoints.searchParentDoc, { searchText }));
  }

  public getDocumentTypes(type: string = '', page: number = 1, limit: number = 9999): Observable<DocumentTypeResponse> {
    return this.http.get<DocumentTypeResponse>(ApiService.getUrl(Endpoints.docTypes), {
      params: {
        type,
        limit: limit.toString(),
        page: page.toString()
      }
    });
  }

  public createDocumentType(type: DocumentTypeModel): Observable<CreateDocumentTypeResponse> {
    return this.http.post<CreateDocumentTypeResponse>(ApiService.getUrl(Endpoints.docTypes), type);
  }

  public editDocumentType(documentTypeId: string, body: DocumentTypeModel): Observable<CreateDocumentTypeResponse> {
    return this.http.patch<CreateDocumentTypeResponse>(ApiService.getUrlWithParams(Endpoints.docTypesById, { id: documentTypeId }), body);
  }

  public deleteDocumentType(documentTypeId: string): Observable<CommonResponse> {
    return this.http.delete<CommonResponse>(ApiService.getUrlWithParams(Endpoints.docTypesById, { id: documentTypeId }));
  }

  public getOffices(name: string = '', limit: number = 9999, page: number = 1): Observable<OfficeResponse> {
    return this.http.get<OfficeResponse>(ApiService.getUrl(Endpoints.offices), {
      params: {
        name,
        limit: limit.toString(),
        page: page.toString()
      }
    });
  }
  public getOfficeTree(): Observable<OfficeResponse> {
    return this.http.get<OfficeResponse>(ApiService.getUrl(Endpoints.officeTree));
  }

  public getGroups(): Observable<GroupsResponse> {
    return this.http.get<GroupsResponse>(ApiService.getUrl(Endpoints.groups));
  }
  public getDocFlows(): Observable<DocFlowsResponse> {
    return this.http.get<DocFlowsResponse>(ApiService.getUrl(Endpoints.docFlows));
  }

  public getNextLevelOfficeFlows(): Observable<OfficeResponse> {
    return this.http.get<OfficeResponse>(ApiService.getUrl(Endpoints.nextLevelDocFlow));
  }

  public createDocFlow(docFlow: FlowModel): Observable<DocFlowResponse> {
    return this.http.post<DocFlowResponse>(ApiService.getUrl(Endpoints.docFlows), {
      office: docFlow.office._id,
      level: docFlow.level
    });
  }

  public deleteDocFlow(id: string): Observable<CommonResponse> {
    return this.http.delete<CommonResponse>(ApiService.getUrlWithParams(Endpoints.docFlowsById, { id }));
  }

  public createDocFlowConnection(id: string, child: string): Observable<DocFlowResponse> {
    return this.http.post<DocFlowResponse>(ApiService.getUrlWithParams(Endpoints.docFlowConnectionById, { id }), {
      child
    });
  }

  public deleteDocFlowConnection(id: string, child: string): Observable<CommonResponse> {
    return this.http.delete<CommonResponse>(ApiService.getUrlWithParams(Endpoints.docFlowConnectionById, { id }), {
      params: {
        child
      }
    });
  }

  public deleteOfficeType(officeTypeId: string): Observable<CommonResponse> {
    return this.http.delete<CommonResponse>(ApiService.getUrlWithParams(Endpoints.officeTypesById, { id: officeTypeId }));
  }


  public getProfile(): Observable<ProfileResponse> {
    return this.http.get<ProfileResponse>(ApiService.getUrl(Endpoints.profile));
  }

  public getOfficeById(officeId: string): Observable<CreateOfficeResponse> {
    return this.http.get<CreateOfficeResponse>(ApiService.getUrlWithParams(Endpoints.officeById, {
      id: officeId
    }));
  }

  public deleteOfficeById(officeId: string): Observable<CommonResponse> {
    return this.http.delete<CommonResponse>(ApiService.getUrlWithParams(Endpoints.officeById, {
      id: officeId
    }));
  }

  public deleteGroupById(groupId: string): Observable<CommonResponse> {
    return this.http.delete<CommonResponse>(ApiService.getUrlWithParams(Endpoints.groupById, {
      id: groupId
    }));
  }
  public modifyOfficeById(officeId: string, office: OfficeModel): Observable<CreateOfficeResponse> {
    return this.http.patch<CreateOfficeResponse>(ApiService.getUrlWithParams(Endpoints.officeById, {
      id: officeId
    }), office);
  }

  public modifyGroupById(groupId: string, group: UserGroupModel): Observable<CreateUserGroupResponse> {
    return this.http.patch<CreateUserGroupResponse>(ApiService.getUrlWithParams(Endpoints.groupById, {
      id: groupId
    }), group);
  }

  public findOfficeByName(officeName: string): Observable<OfficeResponse> {
    return this.http.get<OfficeResponse>(ApiService.getUrlWithParams(Endpoints.officeByName, {
      name: officeName
    }));
  }

  public setUserStatus(userId: string, status: boolean): Observable<UserModel> {
    let requestUrl: string;
    if (status) {
      requestUrl = Endpoints.activateUserById;
    } else {
      requestUrl = Endpoints.deactivateUserById;
    }

    return this.http.post<UserModel>(ApiService.getUrlWithParams(requestUrl, { id: userId }), {});
  }

  public createNewOffice(office: OfficeModel): Observable<CreateOfficeResponse> {
    return this.http.post<CreateOfficeResponse>(ApiService.getUrl(Endpoints.offices), office);
  }

  public createNewGroup(office: UserGroupModel): Observable<CreateUserGroupResponse> {
    return this.http.post<CreateUserGroupResponse>(ApiService.getUrl(Endpoints.groups), office);
  }


  public editUser(userId: string, body: {
    name: string,
    role_id: number,
    office_id: string,
    pwd: string,
    title: string,
    first_name: string,
    last_name: string
  }): Observable<LoginResponse> {
    return this.http.patch<LoginResponse>(ApiService.getUrlWithParams(Endpoints.usersById, {
      id: userId
    }), body);
  }

  public getRoles(): Observable<RoleResponse> {
    return this.http.get<RoleResponse>(ApiService.getUrl(Endpoints.roles));
  }

  public checkIsSender(): Observable<CheckIsSender> {
    return this.http.get<CheckIsSender>(ApiService.getUrl(Endpoints.checkIsSender));
  }

  public createUser(body: {
    name: string,
    role_id: number,
    office_id: string,
    pwd: string,
    title: string,
    first_name: string,
    last_name: string
  }): Observable<LoginResponse> {
    return this.http.post<LoginResponse>(ApiService.getUrl(Endpoints.users), body);
  }

  public getUsers(office_id: string, name: string, limit: number = 9999, page: number = 1): Observable<UsersResponse> {
    return this.http.get<UsersResponse>(ApiService.getUrl(Endpoints.users), {
      params: {
        office_id,
        name,
        limit: limit.toString(),
        page: page.toString()
      }
    });
  }

  public getUsersByName(name: string): Observable<UsersResponse> {
    return this.http.get<UsersResponse>(ApiService.getUrlWithParams(Endpoints.usersByName, { name }));
  }

  public getTrackingStatuses(): Observable<TrackingStatusResponse> {
    return this.http.get<TrackingStatusResponse>(ApiService.getUrl(Endpoints.trackingStatus));
  }

  public getUsersByOffice(officeId: string): Observable<UsersResponse> {
    return this.http.get<UsersResponse>(ApiService.getUrlWithParams(Endpoints.usersOfficeById, { id: officeId }));
  }

  public getUsersForShare(doc_id: string): Observable<UsersResponse> {
    return this.http.get<UsersResponse>(ApiService.getUrlWithParams(Endpoints.usersForShareByDocId, { doc_id }));
  }

  public resetPassword(userID: string, newPassword: string): Observable<UsersResponse> {
    return this.http.post<UsersResponse>(ApiService.getUrl(Endpoints.resetPassword).concat(userID), { password: newPassword });
  }

  public getOrganization(doc_type?: string, type: string = '', name: string = '', limit: number = 9999, page: number = 1): Observable<OrganizationsResponse> {
    return this.http.get<OrganizationsResponse>(ApiService.getUrl(Endpoints.organization), {
      params: {
        doc_type,
        type,
        name,
        page,
        limit
      }
    });
  }

  public getGeneralDepartment(organization: string = null): Observable<GeneralDepartmentsResponse> {
    return this.http.get<GeneralDepartmentsResponse>(ApiService.getUrl(Endpoints.general_department), {
      params: {
        organization
      }
    });
  }

  public getDepartment(general_department: string = null): Observable<DepartmentsResponse> {
    return this.http.get<DepartmentsResponse>(ApiService.getUrl(Endpoints.department), {
      params: {
        general_department
      }
    });
  }
  public createOrganization(data: { doc_type: string, type: string, name: string }): Observable<OrganizationResponse> {
    console.log(data)
    return this.http.post<OrganizationResponse>(ApiService.getUrl(Endpoints.organization), data);
  }
  public createGeneralDepartment(organization: string, name: string): Observable<GeneralDepartmentResponse> {
    return this.http.post<GeneralDepartmentResponse>(ApiService.getUrl(Endpoints.general_department), {
      organization,
      name
    });
  }
  public createDepartment(general_department: string, name: string): Observable<DepartmentResponse> {
    return this.http.post<DepartmentResponse>(ApiService.getUrl(Endpoints.department), {
      general_department,
      name
    });
  }

  public editOrganization(id: string, data: { doc_type: string, type: string, name: string }): Observable<OrganizationResponse> {
    console.log(data)
    return this.http.patch<OrganizationResponse>(ApiService.getUrlWithParams(Endpoints.organizationById, { id }), data);
  }

  public deleteOrganization(id: string): Observable<CommonResponse> {
    return this.http.delete<CommonResponse>(ApiService.getUrlWithParams(Endpoints.organizationById, { id }));
  }


  public uploadFileToDocument(documentId: string, file: File): Observable<HttpEvent<Response>> {

    const formData = new FormData();
    formData.append('file', file);
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data;boundary=abc');

    const req = new HttpRequest('POST', ApiService.getUrlWithParams(Endpoints.uploadFileToDocumentById, {
      id: documentId
    }), formData, {
      reportProgress: true,
      responseType: 'json',
      headers: headers
    });

    return this.http.request<Response>(req);
  }

  public sendDocumentToOffice(documentId: string, body: { files: File[], copied_users: UserModel[], to: string, comment: string, receiver: string, approved_by, hide_previous_comment: boolean }): Observable<HttpEvent<Response>> {
    const formData = new FormData();
    const httpHeaders = new HttpHeaders();
    httpHeaders.append('Content-Type', 'multipart/form-data;boundary=abc');
    if (body.files) {
      Array.from(body.files).forEach(file => formData.append('file', file));
    }

    formData.append('to', body.to);
    formData.append('comment', body.comment);
    formData.append('receiver', body.receiver);
    formData.append('approved_by', body.approved_by);
    body.copied_users?.forEach(user => {
      formData.append('copied_users[]', user._id);
    })

    formData.append('hide_previous_comment', body.hide_previous_comment ? 'true' : 'false');

    return this.http.request<Response>(new HttpRequest('POST', ApiService.getUrlWithParams(Endpoints.trackingSendByDocumentId, {
      id: documentId
    }), formData, {
      reportProgress: true,
      responseType: 'json',
      headers: httpHeaders
    }));
  }

  public sendDocumentBack(documentId: string, body: { files: File[], copied_users: UserModel[], comment: string }, is_approved: boolean = false): Observable<HttpEvent<Response>> {
    const formData = new FormData();
    const httpHeaders = new HttpHeaders();
    httpHeaders.append('Content-Type', 'multipart/form-data;boundary=abc');
    if (body.files) {
      Array.from(body.files).forEach(file => formData.append('file', file));
    }

    formData.append('comment', body.comment);
    body.copied_users?.forEach(user => {
      formData.append('copied_users[]', user._id);
    })
    if (is_approved) {
      formData.append('is_approved', 'true');
    }

    return this.http.request<Response>(new HttpRequest('POST', ApiService.getUrlWithParams(Endpoints.trackingSendBackByDocumentId, {
      id: documentId
    }), formData, {
      reportProgress: true,
      responseType: 'json',
      headers: httpHeaders
    }));
  }

  public completeDocument(documentId: string, body: { files: File[], copied_users: UserModel[], comment: string }): Observable<HttpEvent<Response>> {
    const formData = new FormData();
    const httpHeaders = new HttpHeaders();
    httpHeaders.append('Content-Type', 'multipart/form-data;boundary=abc');
    if (body.files) {
      Array.from(body.files).forEach(file => formData.append('file', file));
    }

    formData.append('comment', body.comment);
    body.copied_users?.forEach(user => {
      formData.append('copied_users[]', user._id);
    })

    return this.http.request<Response>(new HttpRequest('POST', ApiService.getUrlWithParams(Endpoints.trackingCompleteByDocId, {
      id: documentId
    }), formData, {
      reportProgress: true,
      responseType: 'json',
      headers: httpHeaders
    }));
  }

  public shareDoc(doc_id: string, users: string[]): Observable<CreateDocumentResponse> {
    return this.http.post<CreateDocumentResponse>(ApiService.getUrlWithParams(Endpoints.shareDocById, { doc_id }), { users });
  }

  public markAsCompleted(doc_id: string): Observable<CreateDocumentResponse> {
    return this.http.patch<CreateDocumentResponse>(ApiService.getUrlWithParams(Endpoints.markAsCompleted, { doc_id }), {});
  }

  public unsendDoc(doc_id: string): Observable<CreateDocumentResponse> {
    return this.http.post<CreateDocumentResponse>(ApiService.getUrlWithParams(Endpoints.unsend, { doc_id }), {});
  }

  public getBackDoc(doc_id: string): Observable<CreateDocumentResponse> {
    return this.http.post<CreateDocumentResponse>(ApiService.getUrlWithParams(Endpoints.getBack, { doc_id }), {});
  }

  public cancelApprovedDoc(doc_id: string): Observable<CreateDocumentResponse> {
    return this.http.post<CreateDocumentResponse>(ApiService.getUrlWithParams(Endpoints.cancelApproved, { doc_id }), {});
  }

  public deleteDoc(doc_id: string): Observable<CreateDocumentResponse> {
    return this.http.delete<CreateDocumentResponse>(ApiService.getUrlWithParams(Endpoints.editDocById, { id: doc_id }), {});
  }

  public restoreDoc(doc_id: string): Observable<CreateDocumentResponse> {
    return this.http.post<CreateDocumentResponse>(ApiService.getUrlWithParams(Endpoints.restoreDocById, { id: doc_id }), {});
  }

  public getTrackingOffice(): Observable<TrackingsModelResponse> {
    return this.http.get<TrackingsModelResponse>(ApiService.getUrl(Endpoints.trackingOffice));
  }

  public getUrlTrackingAudioFileById(doc_id: string, tracking_id: string, file_id: string, token: string): string {
    return ApiService.getUrlWithParams(Endpoints.trackingAudioDownloadFileById, { doc_id, tracking_id, file_id, token });
  }

  public downloadTrackingFileById(doc_id: string, tracking_id: string, file_id: string, token: string): string {
    return ApiService.getUrlWithParams(Endpoints.trackingDownloadFileById, { doc_id, tracking_id, file_id, token });
  }

  public downloadFileById(doc_id: string, file_id: string, token: string): string {
    return ApiService.getUrlWithParams(Endpoints.downloadFileById, { doc_id, file_id, token });
  }

  public deleteFileById(doc_id: string, id: string): Observable<CommonResponse> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.append('Content-Type', 'multipart/form-data;boundary=abc');
    return this.http.delete<CommonResponse>(ApiService.getUrlWithParams(Endpoints.deleteFileByID, { doc_id: doc_id, id: id }),
      {
        reportProgress: true,
        responseType: 'json',
        headers: httpHeaders
      });
  }

  public countTracking(): Observable<{ status: number, data: number }> {
    return this.http.get<{ status: number, data: number }>(ApiService.getUrl(Endpoints.trackingCount));
  }

  public trackingReceivedDocument(docId: string, comment?: string): Observable<Response> {
    return this.http.post<Response>(ApiService.getUrlWithParams(Endpoints.trackingReceivedByDocId, { id: docId }), { comment });
  }

  public getTrackingOfDocument(documentId: string): Observable<TrackingsModelResponse> {
    return this.http.get<TrackingsModelResponse>(ApiService.getUrlWithParams(Endpoints.trackingDocsById, { id: documentId }));
  }

  public editProfile(body: { first_name: string, last_name: string }): Observable<{ message: string, status: number, profile: UserModel }> {
    return this.http.patch<{ message: string, status: number, profile: UserModel }>(ApiService.getUrl(Endpoints.profile), body);
  }

  public getReports(condiction: any): Observable<ReportsResponse> {
    return this.http.get<ReportsResponse>(ApiService.getUrl(Endpoints.reports), {
      params: condiction
    });
  }

  public getDashboardCount(startDate: string, endDate: string) {
    return this.http.get<DashboardCountResponse>(ApiService.getUrlWithParams(Endpoints.getDashboardCount, { start_date: startDate, end_date: endDate }));
  }

  public getDashboardAdminCount(startDate: string, endDate: string) {
    return this.http.get<DashboardCountResponse>(ApiService.getUrlWithParams(Endpoints.getDashboardAdminCount, { start_date: startDate, end_date: endDate }));
  }


  public getBarChartInfo(startDate: string) {
    return this.http.get<any>(ApiService.getUrlWithParams(Endpoints.barChartInfo, { date: startDate }));
  }

  public countByMenu() {
    return this.http.get<CountByMenuResponse>(ApiService.getUrlWithParams(Endpoints.courntByMenu, {}));
  }

  public getUserGroupConfig() {
    return this.http.get<UserGroupConfigResponse>(ApiService.getUrlWithParams(Endpoints.configById, { id: 'user_group' }));
  }

  public getOrderDocConfig() {
    return this.http.get<OrderDocConfigResponse>(ApiService.getUrlWithParams(Endpoints.configById, { id: 'order_doc' }));
  }
}



