import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './components/app/app.component';
import { AuthGuard } from '../core/guards/auth.guard';
import { LoginGuard } from '../core/guards/login.guard';

const routes: Routes = [
  {
    path: '',
    component: AppComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'Home'
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'documents',
      },
      {
        path: 'documents',
        loadChildren: () => import('./document/document.module').then(m => m.DocumentModule)
      },
      {
        path: 'offices',
        loadChildren: () => import('./office/office.module').then(m => m.OfficeModule)
      },
      {
        path: 'groups',
        loadChildren: () => import('./group/group.module').then(m => m.GroupModule)
      },
      {
        path: 'users',
        loadChildren: () => import('./users/user.module').then(m => m.UserModule)
      },
      {
        path: 'settings',
        loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule)
      },
      {
        path: 'about',
        loadChildren: () => import('./about/about.module').then(m => m.AboutModule)
      },
      {
        path: 'reports',
        loadChildren: () => import('./reports/report.module').then(m => m.ReportModule)
      },
      {
        path: 'summary',
        loadChildren: () => import('./summary/summary.module').then(m => m.SummaryModule)
      }
      ,
      {
        path: 'doc-flow',
        loadChildren: () => import('./document-flow/document-flow.module').then(m => m.DocumentFlowModule)
      },
      {
        path: 'documents/source',
        loadChildren: () => import('./document-source/document-source.module').then(m => m.DocumentSourceModule)
      }
    ]
  },
  {
    path: 'sign-in',
    canActivate: [LoginGuard],
    loadChildren: () => import('./sign-in/sign-in.module').then(m => m.SignInModule),
    data: {
      title: 'Sign In'
    },
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
