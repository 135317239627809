import {NgModule} from '@angular/core';
import {CommonMaterialModule} from './common-material.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';


@NgModule({
  imports: [
    CommonMaterialModule,
    ReactiveFormsModule,
    FormsModule
  ],
  exports: [
    CommonMaterialModule,
    ReactiveFormsModule,
    FormsModule
  ]
})
export class SharedModule {

}
