<h2 mat-dialog-title>{{'top_menu.change_pwd_dialog.title' | translate}}</h2>
<form class="example-form" name="changePasswordFormGroup" [formGroup]="changePasswordFormGroup">
    <div mat-dialog-content>
        <p>
            <mat-form-field class="example-full-width">
                <input matInput placeholder="{{'top_menu.change_pwd_dialog.placeholder_old_pwd' | translate}}" [type]="hide_old ? 'password' : 'text'" [formControl]="oldPasswordFormControl">
                <mat-icon matSuffix (click)="hide_old = !hide_old">{{hide_old ? 'visibility_off' : 'visibility'}}</mat-icon>
                <mat-error *ngIf="oldPasswordFormControl.invalid">{{getOldPasswordErrorMessage()}}</mat-error>
            </mat-form-field>
            <br/>
            <mat-form-field class="example-full-width">
                <input matInput placeholder="{{'top_menu.change_pwd_dialog.placeholder_new_pwd' | translate}}" [type]="hide_new ? 'password' : 'text'" [formControl]="newPasswordFormControl">
                <mat-icon matSuffix (click)="hide_new = !hide_new">{{hide_new ? 'visibility_off' : 'visibility'}}</mat-icon>
                <mat-error *ngIf="newPasswordFormControl.invalid">{{getNewPasswordErrorMessage()}}</mat-error>
            </mat-form-field>
            <br/>
            <mat-form-field class="example-full-width">
                <input matInput placeholder="{{'top_menu.change_pwd_dialog.placeholder_confirm' | translate}}" [type]="hide_confirm ? 'password' : 'text'" [formControl]="confirmPasswordFormControl">
                <mat-icon matSuffix (click)="hide_confirm = !hide_confirm">{{hide_confirm ? 'visibility_off' : 'visibility'}}</mat-icon>
                <mat-error *ngIf="confirmPasswordFormControl.invalid">{{getConfirmPasswordErrorMessage()}}</mat-error>
            </mat-form-field>
        </p>
        <p style="color: #e34603;" *ngIf="checkBothPassword">{{'top_menu.change_pwd_dialog.message.check_both_pwd' | translate}}</p>
        <p style="color: #e34603;" *ngIf="checkConfirmPassword">{{'top_menu.change_pwd_dialog.message.check_confirm_pwd' | translate}}</p>
        <p style="color: #e34603;" *ngIf="checkWrongPassword">{{'top_menu.change_pwd_dialog.message.check_wrong_pwd' | translate}}</p>
        <div mat-dialog-actions>
            <button mat-stroked-button (click)="onNoClick()">{{'top_menu.change_pwd_dialog.button.cancel' | translate}}</button>
            <button mat-button (click)="handleChangePassword()" class="btn-right" [disabled]="changePasswordFormGroup.invalid" [ngClass]="{'btn-disabled': changePasswordFormGroup.invalid}">{{'top_menu.change_pwd_dialog.button.confirm' | translate}}</button>
        </div>
    </div>
</form>
