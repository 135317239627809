import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { tap } from 'rxjs/operators';
import { LoginResponse } from '../models/responses/login.response';
import { Router } from '@angular/router';
import { EMPTY } from 'rxjs';
const REMEMBER_AUTH_KEY = '__AU'
const RANDOM_KEY = 'rk_sd'
@Injectable({ providedIn: 'root' })
export class AuthService {

  constructor(private api: ApiService,
    private router: Router) {
  }

  public authenticate({ userName, password }: { userName: string, password: string }, remember: boolean = true) {
    return this.api.login({ userName, password })
      .pipe(tap(res => {
        if (res.status === 1) {
          this.onAuthenticated(res);
          if (remember) {
            this.rememberUser(userName, password);
          } else {
            this.removeRememberUser();
          }
        } else {
          if(this.router.url != '/sign-in'){
            this.signOut();
          }
          throw new Error('Error');
        }
      }));
  }

  changePassword(newPassword: string, oldPassword: string) {
    let data = this.getRememberUser();
    if (data) {
      return this.api.changeOwnPassword(newPassword, oldPassword)
        .pipe(tap(res => {
          if (res.status === 1) {
            this.rememberUser(data.userName, newPassword);
          }
        }));
    }

    return this.api.changeOwnPassword(newPassword, oldPassword);
  }

  reauthenticate() {
    let data = this.getRememberUser();
    if (data && data.userName && data.password) {
      return this.authenticate(data, true)
    } else {
      this.signOut();
      return EMPTY
    }
  }
  private rememberUser(userName: string, password: string) {
    const prefix = btoa(REMEMBER_AUTH_KEY).replace(/=/g, '');
    const base64 = REMEMBER_AUTH_KEY.replace(/_/g, '') + btoa(userName + ',' + REMEMBER_AUTH_KEY.toLocaleLowerCase() + password);
    const random = new Date().getTime().toString(16);
    localStorage.setItem(RANDOM_KEY, random)
    localStorage.setItem(REMEMBER_AUTH_KEY, prefix + btoa(prefix + random.substr(random.length - 4) + base64));
  }

  private removeRememberUser() {
    localStorage.removeItem(REMEMBER_AUTH_KEY);
  }

  getRememberUser(): { userName: string, password: string } {
    const prefix = btoa(REMEMBER_AUTH_KEY).replace(/=/g, '');
    const item = localStorage.getItem(REMEMBER_AUTH_KEY);
    const random = localStorage.getItem(RANDOM_KEY);
    if (!item) {
      return null;
    }
    try {
      const data = atob(item.replace(prefix, ''))
      const base64 = data.replace(prefix + random.substr(random.length - 4), '').replace(REMEMBER_AUTH_KEY.replace(/_/g, ''), '');
      const [userName, password] = atob(base64).split(',' + REMEMBER_AUTH_KEY.toLocaleLowerCase());
      return { userName, password }
    } catch (error) {
      return null;
    }
  }

  public hasLoggedIn(): boolean {
    return !!this.getToken();
  }

  private saveToken(token: string) {
    localStorage.setItem('access_token', token);
  }

  public saveUserRole(userRoleID: string) {
    localStorage.setItem('user_role_id', userRoleID);
  }

  private saveUserID(userID: string) {
    localStorage.setItem('user_id', userID);
  }

  public saveLocale(locale: string) {
    localStorage.setItem('locale', locale);
  }

  public saveGroupPriority(is_priority: boolean) {
    localStorage.setItem('group_priority', is_priority ? 'true' : '');
  }

  public saveGroupID(_id: string) {
    localStorage.setItem('group_id', _id || '');
  }

  public getGroupID(){
    return localStorage.getItem('group_id');
  }

  public isUserGroupPriority() {
    return localStorage.getItem('group_priority') == 'true';
  }

  public getToken(): string {
    return localStorage.getItem('access_token');
  }

  public clearToken() {
    localStorage.removeItem('access_token');
  }

  public async signOut() {
    this.clearToken();
    this.removeRememberUser()
    await this.router.navigate(['/sign-in']);
  }

  private onAuthenticated(response: LoginResponse) {
    this.saveToken(response.token);
    this.saveUserRole(response.user.role);
    this.saveUserID(response.user._id);
    this.saveGroupID(response.user.group?._id)
    this.saveGroupPriority(response.user.group?.is_priority);
  }
}
