import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, finalize, switchMap, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AuthService } from '../services/auth.service';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  isReauth = false;
  tokenBehaviorSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  constructor(private auth: AuthService) {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.auth.getToken();
    if (token && !req.url.includes('/assets')) {
      return next.handle(this.addToken(req, token)).pipe(catchError(err => {
        if (err instanceof HttpErrorResponse) {
          return this.onError(err, req, next);
        }
      }));
    } else if (!token) {
      if (!req.url.includes('/login')) {
        this.auth.signOut();
      }
    }
    return next.handle(req).pipe(catchError(err => {
      if (err instanceof HttpErrorResponse) {
        return this.onError(err, req, next);
      }
    }));
  }

  private onError(error: HttpErrorResponse, req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (error.status === 401) {
      if (!this.isReauth) {
        this.isReauth = true;
        this.tokenBehaviorSubject.next(null)
        return this.auth.reauthenticate().pipe(
          switchMap((res) => {
            if(res.status == 1){
              this.tokenBehaviorSubject.next(res.token)
              return next.handle(this.addToken(req, res.token));
            }else{
              this.auth.signOut();
              return throwError('');
            }
          }),
          finalize(() => {
            this.isReauth = false;
          })
        );
      } else {
        return this.tokenBehaviorSubject
          .pipe(
            filter(token => token != null),
            take(1),
            switchMap(token => {
              return next.handle(this.addToken(req, token));
            })
          );
      }
    }
    return throwError(error);
  }
  private addToken(req: HttpRequest<any>, token: string) {
    return req.clone({
      setHeaders: {
        "Authorization": 'Bearer ' + token
      }
    });
  }
}
