import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {fromEvent, Observable, throwError} from 'rxjs';
import {Injectable} from '@angular/core';
import {mapTo, retryWhen, switchMap} from 'rxjs/operators';

@Injectable()
export class OfflineInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(retryWhen(errors => {
      if (navigator.onLine) {
        return errors.pipe(switchMap(error => throwError(error)));
      }
      return fromEvent(window, 'online').pipe(mapTo(true));
    }));
  }
}
