import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './components/app/app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MainComponent } from './components/main/main.component';
import { SharedModule } from '../shared/modules/shared.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from '../core/interceptors/auth.interceptor';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule, MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { OfflineInterceptor } from '../core/interceptors/offline.interceptor';
import { ChangePasswordDialogComponent } from './components/change-password-dialog/change-password-dialog.component';

import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDatepickerModule } from '@angular/material/datepicker';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    ChangePasswordDialogComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    MatDialogModule,
    MatListModule,
    MatProgressSpinnerModule,
    MatBadgeModule,
    MatSnackBarModule,
    SharedModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatRippleModule,
    HttpClientModule,
    MatProgressBarModule,
    MatDatepickerModule,
    MatNativeDateModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: OfflineInterceptor,
      multi: true
    },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' }
  ],
  bootstrap: [MainComponent],
  exports: [
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatRippleModule
  ]
})

export class AppModule {
  constructor() {
    const logError = window.console.error;
    window.console.error = (message?: any, ...optionalParams: any[]): void => {
      if (optionalParams?.length) {
        if(optionalParams[0].stack && optionalParams[0].message){
          let maxStack = optionalParams[0].stack?.indexOf('\n')
          maxStack = maxStack > 200 ? 200 : maxStack;
  
          let maxMessage = optionalParams[0].message.indexOf('\n')
          maxMessage = maxMessage > 200 ? 200 : maxMessage;
  
  
          const msg = optionalParams[0].message?.slice(0, maxMessage);
          // const stack = optionalParams[0].stack?.slice(0, maxStack);
          if (msg.includes("ChunkLoadError")) {
            window.location.reload();
          }
          //TODO: check only different error push to server
          // saveLog(location.href, window.navigator.userAgent, msg, stack)
        }
        logError(message, ...optionalParams);
      } else {
        logError(message);
      }
    };
  }
}
