import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class NotificationService {

  private notificationCount$ = new BehaviorSubject(0);

  constructor(private api: ApiService) {

  }

  public getNotification(): Observable<number> {
    return this.notificationCount$.asObservable();
  }


  public refreshNotification() {
    return this.api.countTracking()
      .pipe(map(x => x.data))
      .subscribe(value => this.notificationCount$.next(value));
  }
}
